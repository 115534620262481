<template>

    <div class="card">
        <div class="card-header bg-gray cabecera-asegurado">
            <h3 class="card-title"><i class="fas fa-address-card mr-2"></i>{{ $t('general.asegurado') }}</h3>
            <div class="card-tools">
                <button type="button" class="btn btn-success btn-xs editar-asegurado" id="boton_guardar_servicio" @click="guardar()" >{{ $t('general.guardar') }} </button>
                
                <button type="button" class="btn btn-tool" title="Pantalla completa" data-card-widget="maximize">
                    <i class="fas fa-expand"></i>
                </button>
                <button type="button" class="btn btn-tool ver-asegurado" title="Ver"  v-on:click="editar">
                    <i class="fas fa-edit"></i>
                </button>
                <button type="button" class="btn btn-tool editar-asegurado"  title="Editar" v-on:click="ver">
                    <i class="fas fa-eye"></i>
                </button>                
                <button type="button" class="btn btn-tool ampliar-asegurado" title="Ampliar" v-on:click="ampliar">
                    <i class="fas fa-plus"></i>
                </button>
                <button type="button" class="btn btn-tool reducir-asegurado" title="Reducir" v-on:click="ver">
                    <i class="fas fa-minus"></i>
                </button>
            </div>
        </div>

        <div class="card-body">
            <keep-alive>
                <component @actualizarasegurado="actualizarasegurado" :guardaraseguradotoken="guardaraseguradotoken" v-bind:is="component" :id="id" :servicioext="servicioext" :servicio="servicio" :asegurado="asegurado"/>            
            </keep-alive>
        </div>
        
    </div>
</template>
<script>
import serviciodatosaseguradosimple from './asegurado_simple.vue';
import serviciodatosaseguradodetallado from './asegurado_detallado.vue';
import serviciodatosaseguradoform from './asegurado_form.vue';
import $ from 'jquery'

export default ({
        props: ['id', 'asegurado', 'servicio','servicioext'],

    setup() {
        return;
    },mounted() {
        this.ver();
    },
    components: {
        'servicio-datos-asegurado-simple': serviciodatosaseguradosimple,
        'servicio-datos-asegurado-detallado': serviciodatosaseguradodetallado,
        'servicio-datos-asegurado-form': serviciodatosaseguradoform,
    },
    data (){
        return {
            component: "servicio-datos-asegurado-simple",
            guardaraseguradotoken:false,
        }
    },
    methods: {
        editar(){
            this.component = "servicio-datos-asegurado-form";
            $(".editar-asegurado").show();
            $(".ver-asegurado").hide();
            $(".cabecera-asegurado").removeClass("bg-gray");
            $(".cabecera-asegurado").addClass("bg-black");
        },
        ver(){
            this.component = "servicio-datos-asegurado-simple";
            $(".editar-asegurado").hide();
            $(".reducir-asegurado").hide();
            $(".ampliar-asegurado").show();
            $(".ver-asegurado").show();
            $(".cabecera-asegurado").removeClass("bg-black");
            $(".cabecera-asegurado").addClass("bg-gray");
        },
        ampliar(){
            this.component = "servicio-datos-asegurado-detallado";
            $(".ampliar-asegurado").hide();
            $(".reducir-asegurado").show();
            $(".cabecera-asegurado").removeClass("bg-black");
            $(".cabecera-asegurado").addClass("bg-gray");

        },
        actualizarasegurado(){
            this.$emit("actualizarasegurado");
        },
        guardar() { 
            this.guardaraseguradotoken = !(this.guardaraseguradotoken);
        }
    }
})
</script>